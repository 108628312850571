/* eslint-disable no-restricted-syntax */
import { RouteRecord, RouteConfig, Route } from "vue-router/types/router";
import { Location } from "vue-router";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import { Application } from "modules/core/lib/Holder";
import LocalizationBO from "modules/core/business/LocalizationBO";
import { slugifyTextForLanguage } from "modules/core/lib/Util";
import LanguageBO from "modules/core/business/LanguageBO";
import config from "modules/core/config/app";
import LoginView from "app/components/user/login/Login";
import SessionBO from "../business/SessionBO";

export default (
    appLanguage: string,
    localization: LocalizationBO,
): RouteConfig[] => {
    const list: any[] = [];
    // const list: RouteConfig[] = [];
    const languageList = LanguageBO.getInstance()
        .getLanguageList()
        .filter((item) => item.isActive)
        .map((item) => item.languageCode);

    function prepareName(name: string, lang: string): string {
        return name + (lang !== appLanguage ? `__${lang}` : "");
    }
    const isString = (value: string | undefined): value is string =>
        Boolean(value);
    for (const lang of languageList.filter<string>(isString)) {
        const language = lang.replace("_", "-");
        const languageForPath = config.rareLanguageFallsBackToEnglish
            ? LanguageBO.rareLanguageToOutputLanguage(language)
            : language;
        const publicLanguage = LanguageBO.convertToPublicLanguage(language);

        if (config.showCertificate) {
            list.push({
                name: prepareName("certificate", language),
                path: `/${publicLanguage}/certification`,
                component: () =>
                    import("app/components/certificate/PageCertificate.vue"),
            });
        }

        list.push({
            name: prepareName("home", language),
            path: `/${publicLanguage}`,
            component: () => import("app/components/home/Home"),
        });

        if (config.enableHomeSelector) {
            list.push({
                name: prepareName(
                    RouteGenerator.getInstance().routeNameList.pageHomeSelector,
                    language,
                ),
                path: `/${publicLanguage}/home/selector`,
                component: () =>
                    import(
                        "app/components/home/page-home-selector/PageHomeSelector"
                    ),
            });
        }

        list.push(
            ...[
                {
                    name: prepareName("pageSymptomsSelector", language),
                    path: `/${publicLanguage}/symptoms-selector`,
                    component: () =>
                        import(
                            "app/components/glossary/page-symptoms-selector/PageSymptomsSelector"
                        ),
                },
                {
                    name: prepareName("pageDiseasesSelector", language),
                    path: `/${publicLanguage}/diseases-selector`,
                    component: () =>
                        import(
                            "app/components/glossary/page-diseases-selector/PageDiseasesSelector"
                        ),
                },
                {
                    name: prepareName("pageCommonDiseases", language),
                    path: `/${publicLanguage}/common-diseases`,
                    component: () =>
                        import(
                            "app/components/glossary/page-common-diseases/PageCommonDiseases"
                        ),
                },
                {
                    name: prepareName("dashboard", language),
                    path: `/${publicLanguage}/dashboard`,
                    component: () =>
                        import("app/components/dashboard/Dashboard"),
                },
                {
                    name: prepareName("actionsCovid", language),
                    path: `/${publicLanguage}/recommendations/covid-19`,
                    component: () =>
                        import(
                            "app/components/glossary/glossary-action-list/GlossaryActionList"
                        ),
                },
                {
                    name: prepareName("preTestCovid19", language),
                    path: `/${publicLanguage}/pre-test-covid-19`,
                    component: () =>
                        import("app/components/test-covid/TestCovid"),
                },
                {
                    name: prepareName("checker", language),
                    path: `/${publicLanguage}/checker`,
                    component: () => import("app/components/home/Home"),
                },
                {
                    name: prepareName("startSession", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "evaluator.msgSubTitle",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    redirect: RouteGenerator.getInstance().home(),
                },
                {
                    name: prepareName("login", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.login",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: LoginView,
                },
                {
                    name: "login",
                    path: `/en/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.login",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: LoginView,
                },
                {
                    name: prepareName("accessCode", language),
                    path: `/${publicLanguage}/access-code`,
                    component: () =>
                        import("app/components/user/access-code/AccessCode"),
                },
                ...(Application.main.generalConfig().register.enabled &&
                !Application.main.generalConfig().hospitalMode
                    ? [
                          {
                              name: prepareName("register", language),
                              path: `/${publicLanguage}/${slugifyTextForLanguage(
                                  localization.localeText(
                                      "routes.register",
                                      {},
                                      languageForPath,
                                  ),
                                  languageForPath,
                              )}`,
                              component: () =>
                                  import(
                                      "app/components/user/register/Register"
                                  ),
                          },
                      ]
                    : []),
                {
                    name: prepareName("profile", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.profile",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () =>
                        import("app/components/user/profile/Profile"),
                },
                {
                    name: prepareName("profileActivity", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText("tmk267", {}, languageForPath),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/user/profile/profile-activity/ProfileActivity"
                        ),
                },
                {
                    name: prepareName("profileEdit", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.edit-profile",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/user/profile/profile-edit/ProfileEdit"
                        ),
                },
                {
                    name: prepareName("patientDetail", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText("tmk547", {}, languageForPath),
                        languageForPath,
                    )}`,
                    component: () =>
                        import("app/components/user/profile/Profile"),
                },
                {
                    name: prepareName("patientHistory", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText("tmk374", {}, languageForPath),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/user/profile/profile-history/ProfileHistory"
                        ),
                },
                {
                    name: prepareName("profileMedical", language),
                    path: `/${publicLanguage}/profile/medical`,
                    component: () =>
                        import(
                            "app/components/user/profile/profile-medical/ProfileMedical"
                        ),
                },
                {
                    name: prepareName("profileSettings", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText("tmk373", {}, languageForPath),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/user/profile/profile-settings/ProfileSettings"
                        ),
                },
                {
                    name: prepareName("profileLogout", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.logout",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () =>
                        import("app/components/user/profile/Logout"),
                },
                {
                    name: prepareName("session", language),
                    path: `/${publicLanguage}/session`,
                    component: () => import("app/components/session/Session"),
                    beforeEnter: async (
                        to: Route,
                        from: Route,
                        next: Function,
                    ) => {
                        if (to.query.sessionId) return next();

                        const { session } =
                            await SessionBO.getInstance().doNewSession(
                                // @ts-ignore
                                to.query?.reason ?? "",
                            );

                        const location: Route = {
                            ...to,
                            query: {
                                ...to.query,
                                sessionId: session?.sessionId!,
                            },
                        };
                        return next(location);
                    },
                },
                {
                    name: prepareName("sessionResult", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.results",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/session/session-result/SessionResult"
                        ),
                },
                ...(Application.main.generalConfig().enableGlossary
                    ? [
                          {
                              name: prepareName("glossary", language),
                              path: `/${publicLanguage}/${slugifyTextForLanguage(
                                  localization.localeText(
                                      "routes.diccionario",
                                      {},
                                      languageForPath,
                                  ),
                                  languageForPath,
                              )}`,
                              component: () =>
                                  import("app/components/glossary/Glossary"),
                          },
                          {
                              name: prepareName("glossaryDetail", language),
                              path: `/${publicLanguage}/${slugifyTextForLanguage(
                                  localization.localeText(
                                      "routes.enfermedad",
                                      {},
                                      languageForPath,
                                  ),
                                  languageForPath,
                              )}/:conclusionDescription?`, // old: ?conclusionId
                              component: () =>
                                  import(
                                      "app/components/glossary/glossary-detail/GlossaryDetail"
                                  ),
                          },
                      ]
                    : []),
                ...(Application.main.generalConfig().enableSymptomsInGlossary
                    ? [
                          {
                              name: prepareName("statementDetail", language),
                              path: `/${publicLanguage}/${slugifyTextForLanguage(
                                  localization.localeText(
                                      "evaluator.sintomas",
                                      {},
                                      languageForPath,
                                  ),
                                  languageForPath,
                              )}/:statementdescriptionShort?`, // old: ?conclusionId
                              component: () =>
                                  import(
                                      "app/components/glossary/glossary-detail/GlossaryDetail"
                                  ),
                          },
                      ]
                    : []),
                {
                    name: prepareName("specialists", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.section",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-list/SpecialistList"
                        ),
                },
                {
                    name: prepareName("specialties", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.specialties",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}/:name`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-list/SpecialistList"
                        ),
                },
                {
                    name: prepareName("specialistDetail", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.specialists",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}/:externUserName`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-detail/SpecialistDetail"
                        ),
                },
                {
                    name: prepareName("specialistPaymentDetail", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.specialists",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}/:externUserId/:productId`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-payment-detail/SpecialistPaymentDetail"
                        ),
                },
                {
                    name: prepareName("chat", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.chat",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () => import("app/components/chat/Chat"),
                },
                {
                    name: prepareName("chats", language),
                    path: `/${publicLanguage}/chats`,
                    component: () => import("app/components/chat/Chat"),
                },
                {
                    name: prepareName("chatbot", language),
                    path: `/${publicLanguage}/chatbot`,
                    redirect: (to: Location) => {
                        return RouteGenerator.getInstance().home()!;
                    },
                    // component: () => import("app/components/chatbot/Chatbot")
                },
                {
                    name: prepareName("awsLogin", language),
                    path: `/${publicLanguage}/aws-login`,
                    component: () =>  import("app/components/user/client-link/client-link-login/ClientLinkLogin"),
                },
                {
                    name: prepareName("clientLinkDetail", language),
                    path: `/${publicLanguage}/client-link-detail`,
                    component: () =>
                        import(
                            "app/components/user/client-link/client-link-detail/ClientLinkDetail"
                        ),
                },
                {
                    name: prepareName("clientLinkList", language),
                    path: `/${publicLanguage}/client-link-list`,
                    component: () =>
                        import(
                            "app/components/user/client-link/client-link-list/ClientLinkList"
                        ),
                },

                {
                    name: prepareName("categories", language),
                    path: `/${publicLanguage}/categories`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-specialty-list/SpecialistSpecialtyList"
                        ),
                },
                {
                    name: prepareName("paymentConfirmation", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.paymentConfirmation",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}/:externUserId/:productId`,
                    component: () =>
                        import(
                            "app/components/user/payment-redirection/PaymentRedirection"
                        ),
                },
                {
                    name: prepareName("legalTerms", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.aviso-legal",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () => import("app/components/user/legal/Legal"),
                },
                {
                    name: prepareName("privacyPolicy", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "access.politicaPrivacidad",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () => import("app/components/user/legal/Legal"),
                },
                {
                    name: prepareName("cookiePolicy", language),
                    path: `/${publicLanguage}/cookie-policy`,
                    component: () => import("app/components/user/legal/Legal"),
                },
                {
                    name: prepareName("resetPassword", language),
                    path: `/${publicLanguage}/${slugifyTextForLanguage(
                        localization.localeText(
                            "routes.reset-password",
                            {},
                            languageForPath,
                        ),
                        languageForPath,
                    )}`,
                    component: () =>
                        import(
                            "app/components/user/profile/profile-forgot-password/ProfileForgotPassword"
                        ),
                },
                {
                    name: prepareName("schedule", language),
                    path: `/${publicLanguage}/schedule`,
                    component: () =>
                        import("app/components/user/schedule/Schedule"),
                },
                {
                    name: prepareName("schedule-appointment", language),
                    path: `/${publicLanguage}/appointment`,
                    component: () =>
                        import("app/components/user/schedule/Schedule"),
                },
                {
                    name: prepareName(
                        "schedule-appointment-confirmation",
                        language,
                    ),
                    path: `/${publicLanguage}/appointment/confirmation`,
                    component: () =>
                        import(
                            "app/components/user/schedule-appointment-confirmation/ScheduleAppointmentConfirmation"
                        ),
                },
                {
                    name: prepareName("schedule-timetable", language),
                    path: `/${publicLanguage}/timetable`,
                    component: () =>
                        import("app/components/user/schedule/Schedule"),
                },
                {
                    name: prepareName(
                        RouteGenerator.getInstance().routeNameList
                            .purchaseProduct,
                        language,
                    ),
                    path: `/${publicLanguage}/purchase`,
                    component: () =>
                        import("app/components/user/purchase/Purchase"),
                },
                {
                    name: prepareName(
                        RouteGenerator.getInstance().routeNameList
                            .specialistAdminList,
                        language,
                    ),
                    path: `/${publicLanguage}/specialist/admin`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-admin-list/SpecialistAdminList"
                        ),
                    meta: {
                        needsAdminPermission: true,
                    },
                },
                {
                    name: prepareName(
                        RouteGenerator.getInstance().routeNameList
                            .specialistAdminForm,
                        language,
                    ),
                    path: `/${publicLanguage}/specialist/form`,
                    component: () =>
                        import(
                            "app/components/specialist/specialist-admin-form/SpecialistAdminForm"
                        ),
                    meta: {
                        needsAdminPermission: false,
                    },
                },
            ],
        );
    }

    return list.map((item) => {
        item.path = encodeURI(item.path);

        return item;
    });
};
